.transferOrders {
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  padding: 0px 14px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: hsl(0, 0%, 100%);
  width: 1126px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableContainer {
  width: 1155px;
}
th {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  height: 50px;
}
td {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 31px;
  height: 50px;
}
table {
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  padding-bottom: 20px;
}
.statusSwitch {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 2px solid rgb(111, 111, 111, 0.3);
  padding: 5px 16px;
  color: #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.searchBar {
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 8px;
  padding-top: 7px;
  padding-bottom: 9px;
}
.searchBar::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.searchBarDiv {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  margin-top: 18px;
  margin-left: 20px;
}
.searchIcon {
  margin-bottom: -6px;
  margin-right: 12px;
}
.searchBar:focus {
  background-color: transparent;
  outline: none;
}
.tableRow:hover {
  box-shadow: 0px 0px 0px 1px #02aab0 inset;
}
