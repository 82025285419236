.transferOrders {
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  padding: 14px 14px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  width: 1100px;
}
.tableContainer {
  width: 1126px;
}
th {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  height: 50px;
}
td {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 31px;
  height: 50px;
  text-align: center;
}

.statusSwitch {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 2px solid rgb(111, 111, 111, 0.3);
  padding: 9px 21px;
  color: #02aab0;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}
.statusSwitch:hover {
  color: #fff;
}
.backButton {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 2px solid rgb(111, 111, 111, 0.3);
  padding: 5px 21px;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  margin-left: 32px;
  border-radius: 5px;
}
.backButton:hover {
  color: #02aab0;
}
.searchContainer {
  display: flex;
  align-items: center;
}
.searchPage {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  width: 77px;
  background: transparent;
  padding: 7px 8px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 16px;
}
.search {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.searchPage:focus {
  background-color: transparent;
  outline: none;
}
.searchBar {
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 8px;
  padding-top: 7px;
  padding-bottom: 9px;
}
.searchBar::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.searchIcon {
  margin-bottom: -6px;
  margin-right: 12px;
}
.searchBar:focus {
  background-color: transparent;
  outline: none;
}
.refreshContainer {
  display: flex;
  align-items: center;
}
.refreshButton {
  background: transparent;
  border: none;
  margin-left: 16px;
  cursor: pointer;
}

.refreshButton:hover {
  transform: scale(1.1);
  filter: contrast(500%);
}
.searchBar {
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 8px;
  padding-top: 7px;
  padding-bottom: 9px;
}
.searchBar::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.searchBarDiv {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  margin-left: 20px;
}
.searchIcon {
  margin-bottom: -6px;
  margin-right: 12px;
}
.searchBar:focus {
  background-color: transparent;
  outline: none;
}
.refreshButtonContainer {
  display: flex;
  align-items: center;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 566px;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}

.idImage {
  width: 50px;
  height: 50px;
}
.attachmentLogo {
  margin-right: 8px;
  margin-bottom: -4px;
}
.idFrontSideLink {
  text-decoration: none;
  color: white;
}
.spinnerOverlay {
  height: 4vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.spinnerContainer {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.disabledButton {
  color: #69788c;
  cursor: not-allowed;
}
table {
  overflow: auto;
}
.submittedKYCDetails {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: #212c3d;

  border: 1px solid #212c3d;
  width: 900px;
}
.kycDetails {
  display: grid;
  grid-template-columns: 40% 40% 33%;
  margin-bottom: 10px; /* Add spacing between each set */
  gap: 5px; /* Add a gap between grid items */
  width: 100%; /* Ensure the container takes the full width */
}
.kycDetails {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.kycHeading {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kycValue {
  color: #fff;
  font-family: "Roboto";
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kycDetailContainer {
  display: flex;
  gap: 231px;
}
.ID {
  width: 70px;
  height: 70px;
}
.userDetails {
  color: #fff;
  font-family: "Metrophobic";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: -30px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-bottom: 2px solid #02aab0;
  width: 912px;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  align-items: center;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.tableRow:hover {
  box-shadow: 0px 0px 0px 0.5px #02aab0 inset;
  border-radius: 10px;
}
