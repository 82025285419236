.tvlMainDiv {
  display: grid;
  grid-template-columns: 0.5fr auto;
}
.radeMainInfo {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px 24px calc(24px + 0.4375rem);
  margin-right: 10px;
}
.logoNameContainer {
  cursor: pointer;
  display: flex;
}
.radeLogo {
  margin: auto 5px auto 0px;
  width: 20px;
  height: 20px;
}
.radeHeading {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}
.tvlDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tvlKey {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.tvlValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #02aab0;
}
.chainInfoDiv,
.protocolDiv {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.chainNameDiv,
.collateralrRatioDiv {
  display: flex;
  gap: 120px;
}
.chainKey,
.protocol {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.chainName,
.collateralRatio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.chainTVL,
.collateralPercentage {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.tvlGraphDiv {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  gap: 15px;
  min-height: 380px;
  height: auto;
}
.marketInfoDiv {
  display: flex;
  justify-content: space-between;
  margin: 14px 29px 0px 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #444444;
}
.buttonsDiv {
  display: flex;
  background: #243958;
  border-radius: 5px;
  padding: 6px 10px;
}
.usdButton {
  border: none;
  color: #ffffff;
  padding: 1px 8px;
  border-radius: 5px;
  margin-right: 5px;
}
.ethButton {
  border: none;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
.ethPriceDiv {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.etherLogo {
  width: 7.84px;
  height: 12.76px;
  padding-right: 7px;
}
.ethPrice {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #eaeaea;
}
.priceValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  color: #ffffff;
}
.marketCapDiv,
.tvlVolumeDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tvlVolumeDiv {
  margin-right: 50px;
}
.marketCapKey,
.volume {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.marketCapValue,
.volumeValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #eaeaea;
}
.hour {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}
.divider {
  border-left: 1px solid #444444;
}
.nestAndStakeDetailsDiv {
  margin-top: 13px;
  display: grid;
  grid-template-columns: 0.5fr auto;
  margin-left: 24px;
  margin-bottom: 30px;
}
.nestDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 17px 21px calc(21px + 0.4375rem);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  margin-right: 12px;
}
.nestDetails {
  display: flex;
  justify-content: space-between;
}
.totalNestsKey {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
}
.totalNestsValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #02aab0;
}
.totalActiveKey,
.totalClosedKey,
.totalDefaultsKey {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.totalActiveValue,
.totalClosedValue,
.totalDefaultsValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.stakedDetailsDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
  padding-left: 16px;
  padding-top: 9px;
  padding-bottom: 14px;
}
.tokenStats {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #02aab0;
}
.tokensDetailsDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
}
.tokenDetails {
  display: flex;
  flex-direction: column;
}
.CDPStatsDiv {
  margin-top: 8px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #02aab0;
}
.rudSupplyKey,
.stakedRUDKey,
.vestedTokensKey,
.unvestedTokensKey,
.pledgedETHKey {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
}
.rudSupplyValue,
.stakedRUDValue,
.vestedTokensValue,
.unvestedTokensValue,
.pledgedETHValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}
.lineChart {
  height: 300px;
}
