.changeStatus {
  padding-top: 20px;

  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding-bottom: 32px;
  text-align: center;
}
.radioButtonContainer {
  margin-bottom: 34px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 14px;

  margin-bottom: 27px;
}
.cancelButton {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fd5252;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 4px;
}
.updateButton {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 4px;
}
.showRejectReason {
  display: flex;
  justify-content: center;
}
.inputBox {
  font-family: "Roboto";
  font-weight: 400;
  padding: 9px 12px;
  font-size: 16px;
  line-height: 33px;
  color: white;
  width: 75%;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #02aab0;
  border-radius: 10px;
}
.inputBox::placeholder {
  color: #ffffff;
  font-size: 14px;
}
