.spinnerOverlay {
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerContainer {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
