.canvas-container {
    position: relative;
    width: 750px;
    height: 300px;
  }
  
.canvas-chart {
    display: block;
    box-sizing: border-box;
    width: 750px;      
  }