.returnUserMainDiv {
  display: flex;
  justify-content: space-between;
  margin-right: 30.7%;
  width: 100%;
}

.returnUserMainDivMob {
  margin-right: 0%;
}
.myInvestmentHeading {
  padding-left: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  display: flex;
}
.myInvestmentHeadingMob {
  padding-left: 21px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-right: 0%;
  @media only screen and (max-width: 361px) {
    font-size: 19px;
  }
}

.statisticsMainDiv {
  padding-top: 12px;
  margin-left: 32px;
  padding-bottom: 21px;
  margin-right: 95px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.statisticsMainDivMob {
  padding-top: 19px;
  margin-left: 21px;
  padding-bottom: 20px;
  margin-right: 35px;
  @media only screen and (max-width: 360px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border-radius: 10px;
  height: auto;
  overflow: auto;
  width: 42%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.returnUserStakeTable {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
}
.returnUserStakeTablemob {
  margin-right: 0%;
}
.tableHeading {
  width: 100%;
}
.stakeTableHeading {
  background: #1c2736;
  font-family: "Metrophobic";
  height: 64px;
  font-weight: 400;
  color: #9c99ae;
}
.stakeTableHeadingWeb {
  font-size: 14px;
  line-height: 160%;
  padding-top: 24px;
  padding-bottom: 16px;
}

.stakeTableNest1:hover,
.stakeTableNest2:hover,
.stakeTableNest3:hover {
  // color: #02aab0;
  box-shadow: 0px 0px 0px 2px #02aab0 inset;
}
.stakeTableNest3:hover {
  box-shadow: 0px 0px 0px 2px #02aab0 inset;
}

.stakeTableNest1,
.stakeTableNest3,
.stakeTableNest5 {
  height: 64px;
  font-family: "Metrophobic";
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  cursor: pointer;
}
.stakeTableNest2,
.stakeTableNest4 {
  height: 64px;
  background: #1c2736;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  cursor: pointer;
}
.tableHeading {
  width: 100%;
}

td {
  text-align: center;
}
table {
  border-collapse: collapse;
  box-sizing: border-box;
}

.multipleStakingContainer {
  margin-top: 30px;
}
.stakeButtonInUnlock {
  padding: 6px 5px;
  margin-top: 21px;
  margin-bottom: 21px;
  margin-right: 15px;
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fd5252;
  border-radius: 4px;
}
.stakeButtonInUnlock:hover {
  border: 1px solid #ffffff;
}
.stakeButtonInLock {
  padding: 6px 5px;
  margin-top: 21px;
  margin-bottom: 21px;
  margin-right: 15px;
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a5a2b8;
  border-radius: 4px;
}
.stakeButtonInLock:hover {
  border: 1px solid #ffffff;
}
.locked {
  color: #f9b959;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.unlocked {
  color: #31c5a0;
}
.mainDiv {
  margin-bottom: 30px;
}
.accordionTd {
  text-align: left;
}
.rowHidden {
  display: none;
}
