.dropdownContainer {
  position: relative;
  border-radius: 10px;
  font-family: "Jura";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  margin-right: 20px;
  @media screen and (max-width: 1097px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1019px) {
    font-size: 12px;
  }
  @media screen and (max-width: 385px) {
    font-size: 10px;
  }
  @media screen and (max-width: 360px) {
    font-size: 6px;
    margin-right: 10px;
  }
}
.dropdownTool {
  width: 10px;
  margin-left: 45px;
  @media screen and (max-width: 1150px) {
    margin-left: 15px;
  }
  @media screen and (max-width: 1036px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 399px) {
    margin-left: 5px;
  }
}
.dropdownInput {
  width: 250px;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  border: 1px solid #00cdac;
  border-radius: 5px;
  @media screen and (max-width: 1300px) {
    width: 200px;
    font-size: 16px;
  }
  @media screen and (max-width: 992px) {
    width: 90%;
    height: 42px;
  }
  @media screen and (max-width: 525px) {
    width: 80%;
    height: 42px;
  }
}
.Select {
  border: none;
}
.dropdownMenu {
  position: absolute;
  width: 300px;
  margin-top: 5px;
  padding-top: 19px;
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d43 100%);
  padding-bottom: 20px;
  border-radius: 5px;
  @media screen and (max-width: 1301px) {
    width: 247px;
    font-size: 16px;
  }
  @media screen and (max-width: 992px) {
    font-size: 16px;
    padding: 12px 12px;
    width: 92.5%;
    z-index: 2;
  }
  @media screen and (max-width: 525px) {
    width: 85%;
  }
}

.dropdown-item {
  padding-left: 36px;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
}

.dropdown-item:hover {
  background: #223f56;
  color: #00cdac;
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdown-tag-item {
  background-color: #ddd;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.dropdown-tag-close {
  display: flex;
  align-items: center;
}

.search-box {
  padding: 5px;
  background-color: #eee;
}

.errorMessage {
  margin-left: 67px;
  margin-top: 14px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fd5252;
  @media screen and (max-width: 990px) {
    margin-left: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
}
