.returnUserMainDiv {
  padding: 14px 18px 14px 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.returnUserMainDivMob {
  padding: 10px 0px 10px 21px;
  font-size: 21px;
  line-height: 26px;
}
.stakedRUDInfo,
.vestingSchedule,
.stakedDate,
.maturityDate,
.accuredEarning,
.noOfDaysRemaining {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}
.maturityDateInMobile {
  display: flex;
  flex-direction: column;
}
.detailsHeading {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #9c99ae;
}
.detailsHeadingInMobile,
.detailsHeadingInMob {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #9c99ae;
}
.detailsDiv {
  margin-left: 32px;
  margin-right: 10%;
  display: flex;
  justify-content: space-between;
}
.stakedDateRemainingDaysContainer {
  display: flex;
  margin-left: 32px;
  justify-content: space-between;
}
.stakedDateRemainingDaysContainerMob {
  margin-left: 18px;
}
.details {
  padding: 20px 0px 4px 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #02aab0;
  text-align: left;
  margin-bottom: 0;
}
.accountButton {
  padding: 12px 31px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: rgba(165, 162, 184, 1);
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}

.accountButtonMob {
  padding: 6px 25px;
  font-size: 13px;
  line-height: 16px;
  margin-right: 8px;
}
.mintButton {
  padding: 12px 31px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: rgba(165, 162, 184, 1);
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 5px;
}
.mintButtonMob {
  padding: 6px 25px;
  font-size: 13px;
  line-height: 16px;
  margin-right: 8px;
}
.mintButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 15px;
}
.buttonContainer {
  margin-top: 16px;
  padding-bottom: 29px;
}
.maturityDateVestingScheduleInMobile {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  margin-top: 22px;
  margin-right: 20px;
}
.maturityDateStakedDate {
  display: flex;
  justify-content: space-between;
}
.accruedRewards {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 4px;
  color: #9c99ae;
  margin-top: 3px;
  margin-left: 32px;
  text-align: left;
}
.accruedRewardsMob {
  font-size: 14px;
  line-height: 160%;
  margin-left: 18px;
}
.accruedRewardsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #ffffff;
  margin-left: 32px;
  text-align: left;
}
.accruedRewardsValueMob {
  font-size: 16px;
  line-height: 160%;
  margin-left: 18px;
}
.stakingRewardsContainer {
  margin-top: 42px;
}
.progressiveBarHeading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}
.progressiveBarContainer {
  margin-left: 28px;
  padding-top: 10px;
  padding-left: 21px;
  padding-right: 21px;
  width: 50%;
  padding-bottom: 18px;
  background: #28313d;
  margin-top: 11px;
  border-radius: 5px;
}
.progressiveBarContainerMob {
  margin-left: 18px;
  margin-right: 12px;
  width: 100%;
  margin-top: 20px;
}
.accuredEarningsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #9c99ae;
}
.rewardVested {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.rewardVestedMob {
  font-size: 11px;
}
.questionMark {
  margin-left: 8px;
  margin-top: 6px;
}
.questionMarkMob {
  margin-left: 0px;
  margin-right: 16px;
  margin-top: 22px;
}
.progressiveBarTooltipContainer {
  display: flex;
}
.toolTipStyle {
  background-color: #141515;
  border-radius: 12px;
  width: 200px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: white;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
  z-index: 5;
}
.stakeDetailsValue {
  font-size: 20px;
}
.stakeDetailsValueInMobile {
  font-size: 16px;
}
.stakeDetailsValueMob {
  font-size: 16px;
}
.stakeDetailsValueMobile {
  font-size: 11px;
}
.stakedRUDInfoInMobile {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  margin-right: 42px;
}
.detailsMob {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 18px;
}
.progressiveBarDetailsValue {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 11px;
  line-height: 160%;
  color: #9c99ae;
}
.radeTokenText {
  margin-left: 3px;
}
.stakeDetailsContainer {
  margin-bottom: 30px;
}
.stakeDetailsContainerMain {
  border-top: 1px solid #02aab0;

  background: #2b3b4acf;
  margin: 40px;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 20px;
  padding-top: 20px;
}
