.titleContainer {
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #02aab0;
}

.cardMainDiv {
  margin-right: 30.7%;
}
.cardMainDivMob {
  margin-right: 0px;
}
