.liquidationPanelMainDiv {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px 10px 5px 5px;
  height: auto;
  padding-bottom: 18px;
  margin-bottom: 30px;
}
.liquidationPanelHeader {
  display: flex;
  padding-top: 14px;
  padding-left: 32px;
  padding-bottom: 35px;
}
.buttonsDiv {
  display: flex;
  background: #243958;
  border-radius: 5px;
  padding: 6px 10px;
}
.defaultsButton {
  border: none;
  color: #ffffff;
  padding: 1px 8px;
  border-radius: 5px;
  margin-right: 5px;
}
.collButton {
  border: none;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
.liquidationDetailsDiv {
  padding-left: 32px;
}
.tableHeading {
  width: 76.19%;
}
.liquidationTableHeading {
  background: #1c2736;
  font-family: "Metrophobic";
  height: 64px;
  font-weight: 400;
  color: #9c99ae;
}
.liquidationTableBody {
  height: 64px;
  font-family: "Metrophobic";
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  cursor: pointer;
}
.liquidateButton {
  padding: 6px 13px;
  margin-top: 21px;
  margin-bottom: 21px;
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d44 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  border-radius: 4px;
}
.liquidateButton:hover {
  border: 1px solid #02aab0;
}
.searchBarContainer {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  margin-left: 20px;
}
.searchBar {
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 8px;
  padding-top: 7px;
  padding-bottom: 9px;
}
.searchBar::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.searchIcon {
  margin-bottom: -6px;
  margin-right: 12px;
}
.searchBar:focus {
  background-color: transparent;
  outline: none;
}
.searchContainer {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.searchPage {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  width: 77px;
  background: transparent;
  padding: 7px 8px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 10px;
}
.search {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.searchPage:focus {
  background-color: transparent;
  outline: none;
}
